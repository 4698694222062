import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const OMeni = () => (
  <Layout>
    <SEO title="Ja u svijetu" />
    <div className="styledtext layout__container--med">
      <h3>Ja u svijetu</h3>
      <p><strong>U prostorno-vremenskom kontinuumu</strong></p>
      <p>Nemam TV, kolu koristim isključivo za čišćenje toaleta, Dnevnik Bridget Jones gledam u lipnju, srpnju i kolovozu, obožavam ljude, sanjam otvorenih očiju, hodam po oblacima, svađam se balkanski glasno, jedem čokoladu poslije ponoći, glupiram se u svakoj prilici i neprilici, imam tonu iracionalnih strahova (ako i postoji neki racionalan strah, njega nemam), živim punim plućima ali i umirem na isti način, zaljubljujem se svaki dan iznova u baš taj dan, prečesto mislim da sam u pravu usprkos svim dokazima da nisam, zamaram se sitnicama i uživam u tome, brzo planem a teško se gasim, uživam u svom odrazu u ogledalu, strepim nad onima koje volim.</p>

      <p>
        Jednakom snagom i ludošću volim svoj karakter i krajnosti kojima se na dnevnoj bazi častim. Volim svoju ludost koju svaki dan baš svjesno prigrlim; volim hrabrost, upornost, smjelost, savršeno odmjerenu dozu drskosti i nježnosti, volim svoj nepreprašni (iliti nepresušni) vilinski prah, sanjarski duh. Volim svoje ljudoljuBstvo i strastvenost prema Životu, volim taj medeni okus u ustima koji me svakodnevno budi, slast i živost koja mi kola venama. Ali... najvolim i najobožavam svoju krhkost - pravu, nepatvorenu, žensku krhkost koja me baca na koljena, iz koje svako malo prekipim #baroknogorkim suzama, koja me tjeskobno lomi i ponovo slaže u neslomljivu skulpturu, baš ovakvu kakva sam svakodnevno, prepuna iskustava Živoga Života.
      </p>

      <p>
        U sedmom sam razredu na svoju bilježnicu pjesama napisala: „Ja sam gorka sjemenka terapeutskoga učinka“. Znam da zvuči pubertetski, ali evo, i nekoliko desetljeća poslije stojim iza te patetično-smiješne žive istine. Svi s kojima sam vreću soli pojela znaju da sa mnom nije lako. Isto tako, znaju da je druga strana moje medalje vrijedna svake komplikacije koju, Bog mi je svjedok, znam propisno složit'. Slažem tako, dan po dan, puzlu za puzlom, sliku za slikom; i pri tome (sve)jednako patim i uživam.
      </p>
  
      <p><strong>U linearnom vremenu</strong></p>
      <ul>
        <li>S oblaka jednom nogom sišla i ovozemaljskom stazom krenula 6. rujna 1979.</li>
        <li>Svoje prve korake k usvajanju suvremenog hrvatskog književnog jezika napravila 1986. u područnoj školi Brođanci, te nakon završetka četvrtog razreda nastavila u Osnovnoj školi Bratoljuba Klaića, Bizovac.</li>
        <li>Jezična gimnazija Valpovo obilježila je moje srednjoškolske dane do 1998., kada sam maturirala.</li>
        <li>Filozofski fakultet Osijek odabrala sam 17. srpnja 1998., a titulom profesorice hrvatskoga jezika i književnosti počastih se 11. ožujka 2004.</li>
        <li>Od 1. rujna 2004. do 31. kolovoza 2005. bila sam pripravnica-volonterka u SŠ Valpovo pod mentorskom aurom najčudesnije profesorice svih vremena, Mile Bungić.</li>
        <li>Od 4. rujna 2006. do danas zaposlena sam na mjestu profesorice hrvatskoga jezika u Medicinskoj školi Osijek. Dana 11. studenoga 2006. upisala Poslijediplomski sveučilišni studij Jezikoslovlje na Sveučilištu Josipa Jurja Strossmayera u Osijeku na Filozofskom fakultetu Osijek. Doktorski rad pod naslovom Jezik franjevca Emerika Pavića (1716. – 1780.) obranila 21. srpnja 2016. te stekla akademski stupanj doktora znanosti iz znanstvenog područja humanističkih znanosti, znanstvenoga polja filologije.</li>
      </ul>

      <p><strong>Kontakt</strong></p>
      <p>Email: <a href="mailto:marina.pilj.tomic@gmail.com">marina.pilj.tomic@gmail.com</a></p>


    </div>
  </Layout>
)

export default OMeni
